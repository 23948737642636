/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from "react";
import '../css/main.css';

const Terms = () => {
    document.title = "MML Terms of Service";
    return(
        <div className="content xtraPadding">
            <h1>Terms of Service</h1>
            <h3>For all MML services</h3>
            <br />
            <strong>Published: 7/17/2023</strong>
            <br />
            <strong>Effective: 7/18/2023</strong>
            <br />
            <br />
            <p>
                <strong>Welcome to MML Tech LLC! These Terms of Service ("Terms") govern your use of our online services, including social media apps and multiplayer game services ("Services"). Please read these Terms carefully before using our Services. By accessing or using our Services, you agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms, please do not use our Services.</strong>
                <br />
                <br />
                <h2>Section 1 - Acceptance of Terms</h2>
                <br />
                <ul className="mmlList">
                    <li>By accessing or using our Services, you affirm that you are at least 13 years old and have the legal capacity to enter into this agreement. If you are accessing or using our Services on behalf of a company or organization, you represent that you have the authority to bind that entity to these Terms.</li>    
                </ul>
                <br />
                <br />
                <h2>Section 2 - License to Use</h2>
                <br />
                <ul className="mmlList">
                    <li>Subject to your compliance with these Terms, MML Tech LLC grants you a limited, non-exclusive, non-transferable, revocable license to use our Services for personal, non-commercial purposes. You agree not to modify, distribute, sell, lease, sublicense, or create derivative works based on our Services unless expressly permitted by MML Tech LLC.</li>
                </ul>
                <br />
                <br />
                <h2>Section 3 - User Conduct</h2>
                <br />
                <ul className="mmlList">
                    <li>
                        <ol type='a'>
                            <li>You agree to use our Services in accordance with applicable laws and regulations and not to engage in any activity that:
                                <br />
                                <ol type='i'>
                                    <li>violates or infringes upon the rights of others;</li>
                                    <li>is harmful, threatening, abusive, obscene, or defamatory;</li>
                                    <li>constitutes spam, phishing, or the transmission of any unauthorized or unsolicited advertising, promotional materials, or any other form of solicitation;</li>
                                    <li>interferes with or disrupts the integrity or performance of our Services or the data contained therein;</li>
                                    <li>attempts to gain unauthorized access to our Services or any user accounts, computer systems, or networks connected to our Services;</li>
                                    <li>impersonates any person or entity or falsely represents your affiliation with any person or entity.</li>
                                </ol>
                            </li>
                            <br />
                            <li>You understand that you are solely responsible for your use of our Services and any consequences thereof. MML Tech LLC reserves the right to monitor user activity and to remove or suspend any user accounts or content that violate these Terms or are otherwise objectionable.</li>
                        </ol>
                    </li>
                </ul>
                <br />
                <br />
                <h2>Section 4 - Intellectual Property</h2>
                <br />
                <ul className="mmlList">
                    <li>
                        <ol type='a'>
                            <li>MML Tech LLC retains all rights, title, and interest in and to our Services, including all intellectual property rights. You acknowledge that you do not acquire any ownership rights by using our Services.</li>
                            <li>You agree not to use, copy, distribute, or modify any content from our Services unless expressly permitted by MML Tech LLC or the respective rights holders.</li>
                        </ol>
                    </li>
                </ul>
                <br />
                <br />
                <h2>Section 5 - Third-party Content and Links</h2>
                <br />
                <ul className="mmlList">
                    <li>Our Services may contain links to third-party websites, services, or content that are not owned or controlled by MML Tech LLC. We do not endorse or assume any responsibility for such third-party content. Your interactions with any third-party content or websites are solely between you and the third party, and you should review their terms of service and privacy policies.</li>
                </ul>
                <br />
                <br />
                <h2>Section 6 - Termination</h2>
                <br />
                <ul className="mmlList">
                    <li>MML Tech LLC may, at its sole discretion, suspend, restrict, or terminate your access to our Services at any time without prior notice or liability. You may also terminate your use of our Services at any time.</li>
                </ul>
                <br />
                <br />
                <h2>Section 7 - Disclaimer of Warranties</h2>
                <br />
                <ul className="mmlList">
                    <li>Our Services are provided on an "as is" and "as available" basis. MML Tech LLC does not warrant that our Services will be uninterrupted, error-free, or secure. We disclaim all warranties, whether express or implied, including warranties of merchantability, fitness for a particular purpose, and non-infringement.</li>
                </ul>
                <br />
                <br />
                <h2>Section 8 - Limitation of Liability</h2>
                <br />
                <ul className="mmlList">
                    <li>To the fullest extent permitted by applicable law, MML Tech LLC and its affiliates, officers, directors, employees, agents, and licensors shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with the use or inability to use our Services.</li>
                </ul>
                <br />
                <br />
                <h2>Section 9 - Modifications to Terms</h2>
                <br />
                <ul className="mmlList">
                    <li>MML Tech LLC reserves the right to modify these Terms at any time. We will notify you of any material changes to these Terms, and your continued use of our Services after such changes constitutes your acceptance of the modified Terms.</li>
                </ul>
                <br />
                <br />
                <h2>Section 10 - Governing Law and Jurisdiction</h2>
                <br />
                <ul className="mmlList">
                    <li>These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which MML Tech LLC is registered. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the competent courts in that jurisdiction.</li>
                </ul>
                <br />
                <br />
                <strong>If you have any questions or concerns regarding these Terms, please contact us at <a href='mailto:mmlsite10@gmail.com'>mmlsite10@gmail.com</a> or to contact a specific department see the <a href='/contact'>Contact Page</a>.</strong>
            </p>
            <br />
            <hr className="contentSep" />
            <br />
            <footer className="footer">
                <div className='mml-brands'>
                    <h3>Our Brands</h3>
                    <br />
                    <a href="https://plus.mmltech.net" className='footerLink'>MML+</a>
                    <a href="" className='footerLink'>MML Games</a>
                </div>
                <div className='mml-links'>
                    <h3>Site Links</h3>
                    <br />
                    <a href='/' className="footerLink">Home</a>
                    <a href='/projects' className="footerLink">Projects</a>
                    <a href='/contact' className='footerLink'>Contact</a>
                    <a href='/about' className='footerLink'>About</a>
                </div>
                <div className='mml-legal'>
                    <h3>Legal</h3>
                    <br />
                    <a href='/privacy' className="footerLink">Privacy Policy</a>
                    <a href='/cookies' className="footerLink">Cookie Policy</a>
                    <a href='/terms' className="footerLink">Terms of Service</a>
                </div>
                <div className="mml-social">
                    <h3>Social</h3>
                    <br />
                    <a href="https://facebook.com/MMLtechLLC" className="footerLink fbFooter">Facebook</a>
                    <a href="https://twitter.com/MMLtechLLC" className="footerLink twFooter">Twitter</a>
                    <a href="https://reddit.com/r/mmltech" className="footerLink rdFooter">Reddit</a>
                    <a href="https://mastodon.social/@mmltech" className="footerLink maFooter">Mastodon</a>
                </div>
            </footer>
        </div>
    ); 
}

export default Terms;