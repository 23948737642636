/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from "react";
import '../css/main.css';

const Cookies = () => {
    document.title = "MML Service Cookie Policy";
    return(
        <div className="content xtraPadding">
            <h1>Cookie Policy</h1>
            <h3>For all MML services</h3>
            <br />
            <strong>Published: 7/17/2023</strong>
            <br />
            <strong>Effective: 7/18/2023</strong>
            <br />
            <br />
            <p>
                <strong>This Cookie Policy explains how MML Tech LLC ("we," "us," or "our") uses cookies and similar technologies on our website and online services, including social networks and multiplayer game services (collectively, the "Services"). By using our Services, you agree to the use of cookies as described in this policy.</strong>
                <br />
                <br />
                <h2>Section 1 - What are Cookies?</h2>
                <br />
                <ul className="mmlList">
                    <li>Cookies are small text files that are placed on your device when you visit a website or use an online service. They are widely used to enhance your browsing experience and provide certain functionalities. Cookies can be either temporary (session cookies) or permanent (persistent cookies).</li>
                </ul>
                <br />
                <br />
                <h2>Section 2 - How We Use Cookies</h2>
                <br />
                <ul className="mmlList">
                    <li>We use cookies and similar technologies for various purposes, including:
                        <li><strong>Essential cookies:</strong> These cookies are necessary for the proper functioning of our Services. They enable you to navigate our website and access its features.</li>
                        <br />
                        <li><strong>Performance and Analytics cookies:</strong> We use these cookies to collect information about how visitors use our Services, such as which pages are visited most often, and if users experience any errors. This data helps us improve the performance and functionality of our Services.</li>
                        <br />
                        <li><strong>Advertising and Targeting cookies:</strong> These cookies are used to deliver advertisements that are relevant to you and your interests. They may also be used to limit the number of times you see an advertisement and measure the effectiveness of advertising campaigns.</li>
                        <br />
                        <li><strong>Functionality cookies:</strong> These cookies enable us to remember your preferences and provide enhanced features when you interact with our Services. For example, they may remember your username or language preferences.</li>
                        <br />
                        <li><strong>Third-party cookies:</strong> (ALTHOUGH RARELY USED), we may allow third-party service providers to place cookies on your device through our Services. These providers help us analyze our Services' usage, provide targeted advertisements, and integrate user-to-user features. We do not have control over the cookies placed by third parties, and their use is subject to their respective privacy policies.</li>
                    </li>
                </ul>
                <br />
                <br />
                <h2>Section 3 - Your Cookie Choices</h2>
                <br />
                <ul className="mmlList">
                    <li>By default, most web browsers automatically accept cookies. However, you can usually modify your browser settings to decline cookies or notify you when a cookie is being sent. Please note that if you choose to disable or block certain cookies, some features of our Services may not function properly.</li>
                </ul>
                <br />
                <br />
                <h2>Section 4 - Managing Cookie Preferences</h2>
                <br />
                <ul className="mmlList">
                    <li>To manage your cookie preferences, you can:
                        <li>Adjust your browser settings - most web browsers provide options to manage cookies through their settings. Check your browser's help menu for more information on how to do this.</li>
                        <br />
                        <li>Use third-party cookie management tools - various third-party tools are available that allow you to manage cookies and similar technologies. You can find more information about these tools in your browser's app store or the third-party provider's website.</li>
                    </li>
                    <br />
                    <li>For more information about cookies and how to manage your preferences, visit <a href='https://www.allaboutcookies.org'>www.allaboutcookies.org</a></li>
                </ul>
                <br />
                <br />
                <h2>Section 5 - Updates to this Cookie Policy</h2>
                <br />
                <ul className="mmlList">
                    <li>We may update this Cookie Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this policy periodically for any updates. The "Effective Date" at the top of this policy indicates when it was last revised.</li>
                </ul>
                <br />
                <br />
                <h2>Section 6 - Contact Us</h2>
                <br />
                <ul className="mmlList">
                    <li>If you have any questions or concerns about our use of cookies or this Cookie Policy, please contact us at <a href='mailto:mmlsite10@gmail.com'>mmlsite10@gmail.com</a> or to reach a specific department, see the <a href='/contact'>Contact Page</a>.</li>
                </ul>
            </p>
            <br />
            <hr className="contentSep" />
            <br />
            <footer className="footer">
                <div className='mml-brands'>
                    <h3>Our Brands</h3>
                    <br />
                    <a href="https://plus.mmltech.net" className='footerLink'>MML+</a>
                    <a href="" className='footerLink'>MML Games</a>
                </div>
                <div className='mml-links'>
                    <h3>Site Links</h3>
                    <br />
                    <a href='/' className="footerLink">Home</a>
                    <a href='/projects' className="footerLink">Projects</a>
                    <a href='/contact' className='footerLink'>Contact</a>
                    <a href='/about' className='footerLink'>About</a>
                </div>
                <div className='mml-legal'>
                    <h3>Legal</h3>
                    <br />
                    <a href='/privacy' className="footerLink">Privacy Policy</a>
                    <a href='/cookies' className="footerLink">Cookie Policy</a>
                    <a href='/terms' className="footerLink">Terms of Service</a>
                </div>
                <div className="mml-social">
                    <h3>Social</h3>
                    <br />
                    <a href="https://facebook.com/MMLtechLLC" className="footerLink fbFooter">Facebook</a>
                    <a href="https://twitter.com/MMLtechLLC" className="footerLink twFooter">Twitter</a>
                    <a href="https://reddit.com/r/mmltech" className="footerLink rdFooter">Reddit</a>
                    <a href="https://mastodon.social/@mmltech" className="footerLink maFooter">Mastodon</a>
                </div>
            </footer>
        </div>
    ); 
}

export default Cookies;