/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from "react";
import '../css/main.css';

const Privacy = () => {
    document.title = "MML Service Privacy Policy";
    return(
        <div className="content xtraPadding">
            <h1>Privacy Policy</h1>
            <h3>For all MML services</h3>
            <br />
            <strong>Published: 7/17/2023</strong>
            <br />
            <strong>Effective: 7/18/2023</strong>
            <br />
            <br />
            <p><strong>At MML Tech LLC, we value your privacy and are commited to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safegaurd your information when you access our online services, including social networks and multi-user real-time gaming platforms (collectively referred to as "Services"). Please read this document carefully, so you can understand our privacy practices regarding your information and how we will treat it. By using our services, you agree to all of the practices described in this document ("Privacy Policy").</strong>
                <br />
                <br />
                <h2>Section 1 - Information We Collect</h2>
                <br />
                <ul className="mmlList">
                    <li><h3>1.1: Personal Information:</h3> When you use our services, we may collect some personal information that can be used to identify you. This may include your name, email address, phone number, online usernames, and any other information you provide voluntarily.</li>
                    <br />
                    <li><h3>1.2: Usage Data:</h3> We may collect certain information automatically when you access or use our Services, which includes your IP address, device information such as OS, OS type, OS version, and other info like your browser, access times, and other usage details.</li>
                    <br />
                    <li><h3>1.3: Cookies and Similar Technologies:</h3> We may use cookies, web beacons, and other similar technologies to collect information about your interactions with our Services. This data helps us personalize your experience. You can manage your cookie preference through your browser settings.</li>
                </ul>
                <br />
                <br />
                <h2>Section 2 - How We Use Your Information</h2>
                <br />
                <ul className="mmlList">
                    <li><h3>2.1: Provide and Improve Services:</h3> We use the information we collect to provide, maintain, and improve our Services. This includes personalizing your experience, analyzing usage patterns, troubleshooting issues, and enhancing the security of our systems.</li>
                    <br />
                    <li><h3>2.2: Communication:</h3> We may use your contact information to send you important updates, announcements, and administrative messages related to our Services. We may also use your contact information to respond to your inqueries and provide customer support.</li>
                    <br />
                    <li><h3>2.3: Marketing and Promotional Purposes:</h3> With your consent, we may use your information to send you promotional materials and offers that may be of interest to you. You can opt-out of receiving such communications at any time.</li>
                    <br />
                    <li><h3>2.4: Legal Compliance:</h3> We may use and disclose your information to comply with applicable laws, regulations, legal processes, or governmental requests (ONLY IN THE UNITED STATES). We may also enforce our terms of service or protect our rights, privacy, safety, or property, as well of that of our users.</li>
                </ul>
                <br />
                <br />
                <h2>Section 3 - Information Sharing and Disclosure</h2>
                <br />
                <ul className="mmlList">
                    <li><h3>3.1: Service Providers:</h3> We may engage trusted third-party service providers to assist us in delivering our Services or performing related services. These providers are bound by confidentiality obligations and are prohibited from using your personal information for any other purpose.</li>
                    <br />
                    <li><h3>3.2: Business Transfers:</h3> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring organization. We will notify you via email or prominent notice on our website before your information becomes subject to a different privacy policy.</li>
                    <br />
                    <li><h3>3.3: Legal Requirements:</h3> We may disclose the information of any (United States) user to comply with any applicable law, regulation, legal process, or governmental request. We will take reasonable efforts to notify you of such disclosure, unless prohibited by law or court order.</li>
                </ul>
                <br />
                <br />
                <h2>Section 4 - Data Security</h2>
                <br />
                We employ industry-standard security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission or electronic storage is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your information, we cannot guarantee its absolute security.
                <br />
                <br />
                <h2>Section 5 - Children's Privacy and COPPA (Children's Online Privacy Protection Act)</h2>
                <br />
                <ul className="mmlList">
                    <li>Our Services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe that your child has provided us with personal information without your consent, please contact us, and we will take steps to remove the information from our systems. If you would like a copy of the information, we can provide that. You as a user can report anyone if you believe they are under 13 or your country's legal age.</li>
                </ul>
                <br />
                <br />
                <h2>Section 6 - Your Choices</h2>
                <br />
                <ul className="mmlList">
                    <li><h3>6.1: Account Information:</h3> You may update or correct your account information by accessing your account settings within the respective Services. Please note that we may retain certain information as required by law or for legitimate business purposes.</li>
                    <br />
                    <li><h3>6.2: Marketing Communications:</h3> You can opt-out of receiving promotional emails by following the unsubscribe instructions included in those emails. You may also update your communication preferences through your account settings.</li>
                </ul>
                <br />
                <br />
                <h2>Section 7 - Changes to this document</h2>
                <br />
                <ul className="mmlList">
                    <li>We may update this Privacy Policy from time to time. The revised policy will be posted on our website, and the effective date will be indicated at the top. We encourage you to review the Privacy Policy periodically for any changes.</li>
                </ul>
                <br />
                <br />
                <h2>Section 8 - Contact Us</h2>
                <br />
                <ul className="mmlList">
                    <li>If you have any questions, concerns, or suggestions regarding this Privacy Policy or any other legal document on our site, please contact us at <a href='mailto:mmlsite10@gmail.com'>mmlsite10@gmail.com</a> or if you need to contact a specific department, visit the <a href='/contact'>Contact Us</a> page.</li>
                </ul>
                <br />
                <br />
                <strong>Thank you for trusting us with your personal information. We are committed to protecting your privacy and providing you with a safe and secure experience using our Services.</strong>
            </p>
            <br />
            <hr className="contentSep" />
            <br />
            <footer className="footer">
                <div className='mml-brands'>
                    <h3>Our Brands</h3>
                    <br />
                    <a href="https://plus.mmltech.net" className='footerLink'>MML+</a>
                    <a href="" className='footerLink'>MML Games</a>
                </div>
                <div className='mml-links'>
                    <h3>Site Links</h3>
                    <br />
                    <a href='/' className="footerLink">Home</a>
                    <a href='/projects' className="footerLink">Projects</a>
                    <a href='/contact' className='footerLink'>Contact</a>
                    <a href='/about' className='footerLink'>About</a>
                </div>
                <div className='mml-legal'>
                    <h3>Legal</h3>
                    <br />
                    <a href='/privacy' className="footerLink">Privacy Policy</a>
                    <a href='/cookies' className="footerLink">Cookie Policy</a>
                    <a href='/terms' className="footerLink">Terms of Service</a>
                </div>
                <div className="mml-social">
                    <h3>Social</h3>
                    <br />
                    <a href="https://facebook.com/MMLtechLLC" className="footerLink fbFooter">Facebook</a>
                    <a href="https://twitter.com/MMLtechLLC" className="footerLink twFooter">Twitter</a>
                    <a href="https://reddit.com/r/mmltech" className="footerLink rdFooter">Reddit</a>
                    <a href="https://mastodon.social/@mmltech" className="footerLink maFooter">Mastodon</a>
                </div>
            </footer>
        </div>
    ); 
}

export default Privacy;