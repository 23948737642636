/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from "react";
import '../css/main.css';
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFacebook, faTwitter, faReddit, faMastodon } from "@fortawesome/free-brands-svg-icons";
// library.add(faFacebook, faTwitter, faMastodon, faReddit);
const mmlgames = require('../assets/mmlgames.png');
const cpos = require('../assets/computios.png');

const Index = () => {
    document.title = "MML Tech LLC - Projects";
    return(
        <div className="content">
            <h1 style={{textAlign: "center"}}>Our Projects</h1>
            <br />
            <div className="projectsGrid">
                <div className="projectCard plusCard">
                    <div className="cardHeader">
                        <img src='https://plus.mmltech.net/logo512.png' className="projectImg" alt='MML+ Icon' />
                        <h1 className="projectTitle">MML+</h1>
                    </div>
                    <p className="projectDetails">Made with privacy, usability, and simplicity in mind, MML+ will revolutionize the way social media works, with a highly discoverable algorithm, becoming a large MML+ user is pretty simple. Posting, messaging, group content sharing, its all here. Check it out at <a rel='noreferrer' target='_blank' href='https://plus.mmltech.net' className="link">plus.mmltech.net</a></p>
                </div>
                <div className="projectCard gamesCard">
                    <div className="cardHeader">
                        <img src={mmlgames} className="projectImg" alt='MML Games Icon' />
                        <h1 className="projectTitle">MML Games</h1>
                    </div>
                    <p className="projectDetails">A project in progress right now, MML Games provides a easy, usable way for anyone to upload a game and play it, even with multiplayer gaming support! Just add the MML Gaming API to your game and you can integrate it to make a really powerful game, or just a really simple game.</p>
                </div>
                <div className="projectCard cposCard">
                    <div className="cardHeader">
                        <img src={cpos} className="projectImg" alt='ComputiOS Icon' />
                        <h1 className="projectTitle">ComputiOS</h1>
                    </div>
                    <p className="projectDetails">Another project in progress right now, ComputiOS is a computer operating system, meant to provide the features of Windows, looks of MacOS, and power of Linux, into one beautiful OS. It's lightweight, and releasing in 2024. With features like always on display and extensions, it defeats.</p>
                </div>
            </div>
            <br />
            <hr className="contentSep" />
            <br />
            <footer className="footer">
                <div className='mml-brands'>
                    <h3>Our Brands</h3>
                    <br />
                    <a href="https://plus.mmltech.net" className='footerLink'>MML+</a>
                    <a href="" className='footerLink'>MML Games</a>
                </div>
                <div className='mml-links'>
                    <h3>Site Links</h3>
                    <br />
                    <a href='/' className="footerLink">Home</a>
                    <a href='/projects' className="footerLink">Projects</a>
                    <a href='/contact' className='footerLink'>Contact</a>
                    <a href='/about' className='footerLink'>About</a>
                </div>
                <div className='mml-legal'>
                    <h3>Legal</h3>
                    <br />
                    <a href='/privacy' className="footerLink">Privacy Policy</a>
                    <a href='/cookies' className="footerLink">Cookie Policy</a>
                    <a href='/terms' className="footerLink">Terms of Service</a>
                </div>
                <div className="mml-social">
                    <h3>Social</h3>
                    <br />
                    <a href="https://facebook.com/MMLtechLLC" className="footerLink fbFooter">Facebook</a>
                    <a href="https://twitter.com/MMLtechLLC" className="footerLink twFooter">Twitter</a>
                    <a href="https://reddit.com/r/mmltech" className="footerLink rdFooter">Reddit</a>
                    <a href="https://mastodon.social/@mmltech" className="footerLink maFooter">Mastodon</a>
                </div>
            </footer>
        </div>
    ); 
}

export default Index;