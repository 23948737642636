/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from "react";
import '../css/main.css';
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faReddit, faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";
import { faSms, faEnvelope } from "@fortawesome/free-solid-svg-icons";
library.add(faTwitter, faFacebookMessenger, faReddit, faSms, faEnvelope);

const Contact = () => {
    document.title="Contact MML Tech LLC";
    return(
        <div className="content xtraPadding">
            <h1 style={{textAlign: "center"}}>Contact Us</h1>
            <br />
            <div className="contactOurBrand">
                <h1>Contact Our Brand</h1>
                <br />
                <div className="gridContainer thirdsColumns" style={{margin: 8}}>
                    <div className="socialCard messengerCard">
                        <a href='https://m.me/MMLtechLLC' className="justText">
                            <FontAwesomeIcon icon='fa-brands fa-facebook-messenger' className="bigIcon" />
                            <br />
                            <br />
                            <strong>Click or tap this card to send a message to us through Messenger.</strong>
                        </a>
                    </div>
                    <div className="socialCard redditCard">
                        <a href='https://reddit.com/r/mmltech' className="justText">
                            <FontAwesomeIcon icon='fa-brands fa-reddit' className="bigIcon" />
                            <br />
                            <br />
                            <strong>Click or tap this card to reach out to our Reddit community.</strong>
                        </a>
                    </div>
                    <div className="socialCard twitterCard">
                        <a href='https://twitter.com/messages/compose?recipient_id=1650660307075846145' className="justText">
                            <FontAwesomeIcon icon='fa-brands fa-twitter' className="bigIcon" />
                            <br />
                            <br />
                            <strong>Click or tap this card to send a message to us through Twitter.</strong>
                        </a>
                    </div>
                </div>
            </div>
            <br />
            <div className="contactSpecificDept">
                <h1>Contact a Specific Department</h1>
                <br />
                <div className="gridContainer thirdsColumns" style={{margin: 8}}>
                    <div className="deptCard ceoCard">
                        <h2>Michael Peace (MML)</h2>
                        <strong>Chairman, CEO, Quality Assurance, Developer, Tester</strong>
                        <br />
                        <div className="gridContainer secondsColumns" style={{margin: 8}}>
                            <a href='mailto:michaelpeace937@gmail.com' className="justText emailCard">
                                <FontAwesomeIcon icon='fa-solid fa-envelope' className="bigIcon" />
                                <br />
                                <strong>Click or tap this card to contact me via E-mail!</strong>
                            </a>
                            <a href='sms:+19893100008?&body=Hi%2520there,%2520MML,%2520' className="justText smsCard">
                                <FontAwesomeIcon icon='fa-solid fa-sms' className="bigIcon" />
                                <br />
                                <strong>Click or tap this card to contact me via text!</strong>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <hr className="contentSep" />
            <br />
            <footer className="footer">
                <div className='mml-brands'>
                    <h3>Our Brands</h3>
                    <br />
                    <a href="https://plus.mmltech.net" className='footerLink'>MML+</a>
                    <a href="" className='footerLink'>MML Games</a>
                </div>
                <div className='mml-links'>
                    <h3>Site Links</h3>
                    <br />
                    <a href='/' className="footerLink">Home</a>
                    <a href='/projects' className="footerLink">Projects</a>
                    <a href='/contact' className='footerLink'>Contact</a>
                    <a href='/about' className='footerLink'>About</a>
                </div>
                <div className='mml-legal'>
                    <h3>Legal</h3>
                    <br />
                    <a href='/privacy' className="footerLink">Privacy Policy</a>
                    <a href='/cookies' className="footerLink">Cookie Policy</a>
                    <a href='/terms' className="footerLink">Terms of Service</a>
                </div>
                <div className="mml-social">
                    <h3>Social</h3>
                    <br />
                    <a href="https://facebook.com/MMLtechLLC" className="footerLink fbFooter">Facebook</a>
                    <a href="https://twitter.com/MMLtechLLC" className="footerLink twFooter">Twitter</a>
                    <a href="https://reddit.com/r/mmltech" className="footerLink rdFooter">Reddit</a>
                    <a href="https://mastodon.social/@mmltech" className="footerLink maFooter">Mastodon</a>
                </div>
            </footer>
        </div>
    ); 
}

export default Contact;